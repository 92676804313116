<template>
    <div class="doc-box">

        <slot></slot>
        <voffice-popup-dialog :trigger-id="triggerId"
            ref="dlg"
            :header-label="subject"
            :apply-label="voMsg('close')">

            <div v-html="content">
            </div>

            <div class="spinner"
                v-show="!content && !reasonText">
                <div class="bounce1"></div>
                <div class="bounce2"></div>
                <div class="bounce3"></div>
            </div>

            <div v-show="reasonText"
                class="alert alert-danger"
                role="alert">

                {{reasonText}}

            </div>

        </voffice-popup-dialog>

    </div>


</template>

<script>
    import vofficePopupDialog from './PopupDialog.vue';
    import V from 'voUtils/V.js'

    import {
    	doReq
    }
    from 'voUtils/RestEx.js';

    export default {
    	voVueComponent: 'voffice-doc-box',
    	components: {
    		vofficePopupDialog
    	},
    	props: {
    		triggerId: String,
    		docId: String
    	},
    	data: function() {
    		return {
    			content: undefined,
    			subject: undefined,
    			reasonText: undefined
    		}
    	},
    	mounted: function() {
    		this.$el.firstChild.addEventListener('click', e => {
    			e.preventDefault();
    			e.stopPropagation();

    			this.updateContent();
    			this.$refs.dlg.show();

    		});
    	},
    	methods: {
    		updateContent: function() {
    			this.reasonText = undefined;
    			if (!this.content) {
    				let req = {
    					type: this.docId
    				}
    				doReq('getDoc', req)
    					.then(res => {
    						this.subject = res.doc.subject;
    						this.content = res.doc.content;

    					}).catch(res => {
    						this.reasonText = res.reasonText;
    					});

    			}
    		}
    	}

    };
</script>

<style scoped>
    .doc-box {
    	display: inline-block;
    }

    a {
    	color: #337ab7
    }
</style>